.UserIndex {
  .text-center {
    text-align: center;
  }

  .MuiTableCell-root {
    padding: 16px 8px;
  }

  tr td:first-child {
    padding: 4px 5px;
    width: 30px;
  }

  .jXxTNq tr td:first-child {
    padding: 4px 5px;
    width: 30px;
  }
  .layoutContentWrapper {
    padding-top: 0px;
  }
  .efwKw {
    padding-top: 20px;
  }
  .csXRxQ h3 {
    margin: 40px;
  }
  .Connect\(Users\)-root-5 {
    overflow-x: hidden;
  }
  .filterButtons {
    margin-left: auto;
  }
  .mateFormsSubmit {
    float: right;
    & button {
      margin-right: 10px;
    }
  }
  .filter {
    margin-left: 2px;
  }
  .mainFormsWrapper .mainFormsInfoWrapper .mainFormsInfoField {
    display: flex;
    width: auto;
    min-width: 150px;
    padding: 10px 5px;
    margin-bottom: 20px;
  }
  .multiple-inputs {
    display: flex;
    width: 100%;
    justify-content: space-around;

    > div {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.boxProtocol {
  .boxProtocolButton {
    margin-right: 10px;
  }
}

@media (max-width: 425px) {
  .boxProtocol {
    flex-direction: column;
    -webkit-align-items: self-start !important;

    .boxProtocolName {
      margin: 0;
    }

    .boxProtocolButton {
      margin-bottom: 10px;
    }
  }
}

.dropzoneTextStyle {
  text-align: center;
}

.dropzoneParagraph {
  font-size: 16px
}

.dropZone {
  position: relative;
  width: 100%;
  height: 160px;
  border: 1px dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0px 20px;
}

.stripes {
  width: 100%;
  height: 250px;
  cursor: pointer;
  border: solid;
  border-color: #C8C8C8;
  background-image: repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px);
  -webkit-animation: progress 2s linear infinite !important;
  -moz-animation: progress 2s linear infinite !important;
  animation: progress 2s linear infinite !important;
  background-size: 150% 100%;
}

.rejectStripes {
  width: 100%;
  height: 250px;
  cursor: pointer;
  border: solid;
  border-color: #C8C8C8;
  background-image: repeating-linear-gradient(-45deg, #fc8785, #fc8785 25px, #f4231f 25px, #f4231f 50px);
  -webkit-animation: progress 2s linear infinite !important;
  -moz-animation: progress 2s linear infinite !important;
  animation: progress 2s linear infinite !important;
  background-size: 150% 100%;
}

.fileIconImg {
  color: #d0d0d0 !important;
}

.smallPreviewImg {
  height: 92px !important;
  width: initial !important;
  max-width: 100%;
}

@-webkit-keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -75px 0;
  }
}

@-moz-keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -75px 0;
  }
}

@-ms-keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -75px 0;
  }
}

@keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -70px 0;
  }
}

.imageContainer {
  color: rgba(0, 0, 0, 0.87);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 2px;
  z-index: 5;
  overflow: hidden;
  padding: 0px 10px;
  height: 55px;
  position: relative;
  border: 1px solid #bbb;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
}

.imageContainer:hover .smallPreviewImg {
  opacity: 0.7;
}

.imageContainer:hover .middle {
  opacity: 1;
}

.imageContainer:hover .middleBigPic {
  opacity: 1;
}

.removeBtn {
  color: #f4231f;
  z-index: 3;
}

.middle {
  transition: .5s ease;
  position: absolute;
  top: 0;
  right: 5px;
  z-index: 20
}

.row {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.preview {
  margin: 10px 0px
}

.imgWrap {
  background: #e2e2e2;
}

.fileWrap {
  background: #e2e2e2;
  color: #969696;
}

.downloadBtn {
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userFilterForm {
  margin-bottom: 12px;
  .MuiFormControl-root {
    width: 100%;
  }
  .form--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .mateFormsSubmit {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .MuiButton-containedPrimary {
    margin-right: 10px;
  }
}

.MuiFormControl-root {
  width: 100%;
}

.NewProtocolDialog {
  .UserIndex {
    padding: 0px;
  }
  .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;

    h6 {
      margin-top: 12px;
      font-size: 1.40rem;
    }
  }
}

.UserDialog {
  .MuiDialogContent-root,
  .MuiDialog-paper {
    overflow-y: visible;
    width: 600px;
    @media (max-width: 600px) {
      width: auto;
    }
  }
  .layoutContentWrapper {
    padding: 0px;
  }

  .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;

    h6 {
      margin-top: 12px;
      font-size: 1.40rem;
    }
  }
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
}

.ReopenTicketDialog {
  .MuiDialogContent-root,
  .MuiDialog-paper {
    overflow-y: visible;
  }
  h6 {
    padding-right: 35px;
  }
  .closeButton {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  @media screen and (max-width: 767px) {
    .MuiPaper-root {
      margin: 16px 5px;
    }
  }
}

.createProtocolStepper {
  @media (max-width: 425px) {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .MuiStepConnector-root {
    min-width: 5% !important;

    @media (max-width: 425px) {
      min-width: 100% !important;

      padding: 5px !important;
    }
  }
}

.MuiDialogTitle-root {
  display: flex;
  justify-content: space-between;

  h6 {
    margin-top: 12px;
    font-size: 1.4rem;
  }
}

.CreateProtocol {
  .MuiInputLabel-outlined {
    z-index: 0;
  }
}

.userDashboard {
  padding: 15px;
  margin: 15px;
  background: white;
  .createProtocolForm {
    .MuiFormControl-root {
      display: flex;
    }
    @media (max-width: 767px) {
      .MuiStepLabel-label {
        margin-top: 10px;
      }
    }
  }
}

.mapContainer {
  width: 100%;
  height: 400px;
  z-index: 0;
}

.boxContainer {
  margin: 0px 24px;
  .labelSelect {
    margin-bottom: 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    display: block;
    font-family: 'Quicksand', sans-serif;
    line-height: 1.43;
  }
  .MuiFormControl-root {
    margin-bottom: 10px;
    .labelSelect {
      margin-bottom: 10px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 400;
      display: block;
    }
  }
}

.selectSector {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.selectorBox {
  cursor: pointer;
  background: white;
  color: #222;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  height: 100%;
  .iconResponse {
    font-size: 50px;
  }
}

sectorSelected {
  border: solid 2px;
}

.selectorBoxWrapper {
  min-height: 100px;
  height: 100%;
  position: relative;
  background: linear-gradient(to right, #ff80ab, #f44336);
  padding: 3px;
  border-radius: 5px;
}

.selectedSector {
  background: linear-gradient(to right, #00c853, #b2ff59);
}

@media (max-width: 775px) {
  .MuiStepper-root {
    display: block;
  }
}

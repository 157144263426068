/********** Add Your Global CSS Here **********/
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
button,
html,
body,
html a {
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

a,
button,
li,
span {
  -webkit-tap-highlight-color: transparent;
}

ul {
  margin: 0;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-track-x {
  height: 5px !important;
}

.scrollbar-track-y {
  width: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb-y {
  width: 5px !important;
}
.MuiButton-containedPrimary{
  font-weight: 700 !important;
}
.MuiFormHelperText-root {
  color: #C62828 !important;
}
.MuiButton-root{
  font-weight: 700 !important;
  font-size: 12px;
}
.MuiInputBase-root{
  font-family: 'Quicksand', sans-serif !important;
}
.MuiTableRow-footer .MuiTableCell-root{
  border-bottom: none;
}
.MuiTableRow-footer span{
  font-size: 16px;
}
.MuiTableCell-root {
  font-family: 'Quicksand', sans-serif !important;
}
.CiLogo{
  width: 289px;
}
.textCenter{
  text-align: center;
}
.labelSelect{
  font-size: 14px;
}
.text-center {
  text-align: center;
}

.makeStyles-toolbar-10 {
  min-height: 64px !important;
}

@media (min-width: 600px) {
  .MuiDrawer-paper {
    width: 62px;
  }
}

@media (max-width:380px){
  .MuiIconButton-root {
    padding:6px !important;
  }
}

.MuiDialog-root{
  left: 0vw !important;
}

@media (max-width: 600px){
  .MuiButton-root{
    font-size: 0.475rem!important;
  }
}
@media (max-width: 1400px){
  .MuiButton-root{
    font-size: 0.760rem!important;
  }
}


@media (max-width: 480px) {
  .CiLogo{
    width: 180px;
  }

  .MuiDialog-root{
    left: 0px !important;
  }
}

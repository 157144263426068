#topbarUserIcon {
  display: flex;
  align-items: center;

  .topbarUserInfo {
    float: left;
  }

  .topbarUserName {
    color: #5f5f5f;
    font-weight: bolder;
  }

  .topbarLastLogin {
    color: #aaaaaa;
    font-weight: bolder;
  }
}

.dropdownListItem {
  display: block;
  width: 97%;
  align-items: baseline;
  h5 {
    font-size: 15px !important;
  }

  p {
    font-size: 13px !important;
  }


}